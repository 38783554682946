.header-container {
    background-image: linear-gradient(rgba(173, 216, 230, 0.5),rgba(255, 166, 0, 0.4)), url('./Banner3.jpg');
    color: white;
    background-size: auto 100%;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat, no-repeat;
    height: 100vh;
}

@media screen and (min-width: 155rem) {
    .header-container {
        background-size: 100% auto;
    }
}

.header-text {
    text-transform: uppercase;
    font-size: 40px;
    letter-spacing: 15px;
    font-weight: 400;
}
